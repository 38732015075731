main {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;

  background-color: black;
}

canvas {

  @include canvas {
    display: none;
  }
}

img {
  display: none;
  width: 80%;
  height: auto;

  animation: bounce  3s infinite linear;

  @include canvas {
    display: block;
  }
}

@keyframes bounce {
  from {transform: scale(0.9);}
  50% {transform: scale(1.1);}
  to {transform: scale(0.9);}
}

ul {
  position: absolute;
  top: 70%;

  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  list-style: none;
}

li {
  margin-right: 20px;

  &:last-child {
    margin-right: 0;
  }
}

.telegram,
.skype,
.viber,
.email {
  width: 50px;
  height: 50px;
  display: block;

  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.telegram {
  background-image: url("../img/telegram.png");
}

.skype {
  background-image: url("../img/skype.png");
}

.viber {
  background-image: url("../img/viber.png");
}

.email {
  background-image: url("../img/mail.png");
}
